<template>
    <div></div>
</template>

<script>
// import axios from 'axios'
export default {
    // server redirects to this after user cancels or fails to checkout

    mounted() {
        this.updateUser()
    },

    methods: {
        async updateUser() {
            try {
                // let payload = {
                //     user_id: this.$session.get('user').id
                // }

                if(this.$session.get('user').status === false) {
                    this.$router.push('/join')
                }

                // await axios.post(process.env.VUE_APP_API_URL +'/membership/cancel', payload, { headers: { "Content-Type": "application/json" } })
            } catch (error) {
                console.log(error)
            }
        }
    }
}
</script>

<style>

</style>